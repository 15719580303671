import { lazy } from 'react';

export const ROUTES = {
  PORTFOLIOS: 'portfolios-url',
  EVENTS: 'events-url',
  BLOGS: 'blogs-url',
  WEBINARS: 'webinars-url',
} as const;

export const PortfolioHome = lazy(
  async () => await import('@pages/PortfolioHome')
);

export const PortfolioDetails = lazy(
  async () => await import('@pages/PortfolioDetails')
);

export const EventHome = lazy(async () => await import('@pages/EventHome'));
export const Agenda = lazy(async () => await import('@pages/Agenda'));
export const Speakers = lazy(async () => await import('@pages/Speakers'));
export const Partners = lazy(async () => await import('@pages/Partners'));
export const EventPage = lazy(async () => await import('@pages/EventPage'));
export const EventInfo = lazy(async () => await import('@pages/EventInfo'));
export const Community = lazy(async () => await import('@pages/Community'));
export const Contact = lazy(async () => await import('@pages/Contact'));
export const HelmetPage = lazy(async () => await import('@pages/HelmetPage'));
export const NotFound = lazy(async () => await import('@pages/NotFound'));
