import { CenterItem, Column } from '@components/LinearLayout';
import { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorImg, RootWrapper } from '@pages/NotFound/styles';
import { H5, H6 } from '@components/Typography';
import styled, { useTheme } from 'styled-components';

import Button from '@components/Button';
import GlobalStyle from '@theme/GlobalStyle';
import hubby_m_01 from '@assets/images/hubby_m_01.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Root = styled(CenterItem)`
  height: 100%;
  text-align: center;
`;

export default class ErrorBoundary extends Component<
  { children: ReactNode; fallBackUIComponent: JSX.Element },
  { hasError: boolean }
> {
  constructor(
    props:
      | { children: ReactNode; fallBackUIComponent: JSX.Element }
      | Readonly<{ children: ReactNode; fallBackUIComponent: JSX.Element }>
  ) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.log({ error });

    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallBackUIComponent;
    }
    return this.props.children;
  }
}

export const FallBackUI = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Root>
      <GlobalStyle />
      <RootWrapper>
        <ErrorImg src={hubby_m_01} alt="error image" />
        <Column $gap="1rem">
          <H5>{t('something-went-wrong')}</H5>
          <H6></H6>
        </Column>

        <Button
          title={t('go-back-to-home-page')}
          backgroundColor={theme.color.mobilityBlue}
          hoverBackgroundColor={theme.color.mobilityGrey}
          textColor={theme.color.white}
          borderRadius={theme.borderRadius.radius10}
          padding="0.5rem 2.5rem"
          width="max-content"
          onClick={() => {
            navigate('/');
            window.location.reload();
          }}
        />
      </RootWrapper>
    </Root>
  );
};
