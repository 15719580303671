import {
  CustomResponseType3,
  VisitorDetails,
} from '@hubs101/booking-api-skd-client/lib/types';

import { MutateOptions } from '@tanstack/react-query';

export const cookieInboundName = 'we_client_1.2.5';
export const cookieGdprName = 'gdpr';

const calculateExpiryDate = (days: number): string => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  return `expires=${date.toUTCString()}`;
};

const encodeValue = (value: string): string => encodeURIComponent(value);
const decodeValue = (value: string): string => decodeURIComponent(value);

export const setCookie = (name: string, value: string, days: number): void => {
  const expires = calculateExpiryDate(days);
  const encodedValue = encodeValue(value);
  document.cookie = `${name}=${encodedValue}; ${expires}; path=/`;
};

export const getCookie = (name: string): string | null => {
  const nameEQ = `${name}=`;
  const cookie = document.cookie
    .split(';')
    .map(cookie => cookie.trim())
    .find(cookie => cookie.startsWith(nameEQ))
    ?.substring(nameEQ.length);
  return cookie ? decodeValue(cookie) : null;
};

export const deleteCookie = (name: string): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

export const handleVisitorCreation = async (
  data: Record<string, string>,
  mutateCreateVisitor: (
    variables: 'create' | 'update',
    options?:
      | MutateOptions<VisitorDetails, Error, 'create' | 'update', unknown>
      | undefined
  ) => Promise<VisitorDetails>,
  mutateCreateInbound: (
    variables: string | undefined,
    options?:
      | MutateOptions<CustomResponseType3, Error, string | undefined, unknown>
      | undefined
  ) => void,
  updateCookie: (args: {
    eventId: string;
    portfolioId: string;
    data: Record<string, string>;
  }) => void,
  eventId: string,
  portfolioId: string
) => {
  const response = await mutateCreateVisitor('create');
  const responseDetails = JSON.stringify({
    visitor: response?.id,
    secret: response?.secret,
  });
  mutateCreateInbound(responseDetails);
  updateCookie({
    eventId,
    portfolioId,
    data,
  });
};

export const handleExistingVisitor = (
  data: Record<string, string>,
  mutateCreateInbound: (
    variables: string | undefined,
    options?:
      | MutateOptions<CustomResponseType3, Error, string | undefined, unknown>
      | undefined
  ) => void,
  updateCookie: (args: {
    eventId: string;
    portfolioId: string;
    data: Record<string, string>;
  }) => void,
  eventId: string,
  portfolioId: string,
  secret: string
) => {
  mutateCreateInbound(secret);
  updateCookie({
    eventId,
    portfolioId,
    data,
  });
};
