import {
  InboundData,
  VisitorDataInput,
} from '@hubs101/booking-api-skd-client/lib/types';
import {
  getPublicAgenda,
  getPublicAttendees,
  getPublicCoLocatedEventsByEventId,
  getPublicEventDetailsById,
  getPublicEventDetailsBySlug,
  getPublicEventExhibitions,
  getPublicEventPageDetailsBySlug,
  getPublicGroupDetailsById,
  getPublicPortfolioEvents,
  getPublicPortfoliosByAccount,
  getVisitor,
  postInbound,
  postVisitor,
} from '@hubs101/booking-api-skd-client';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';

import { safeJSONParse } from './fn';

export const ROOT_URL = process.env.ROOT_URL as string;
export const BASE_MEDIA_URL = process.env.BASE_MEDIA_URL as string;
export const ACCOUNT_ID = process.env.ACCOUNT_ID as string;
export const WE_WEBSITE_RE_CAPTCHA_SITE_KEY = process.env
  .RECAPTCHA_KEY as string;

export const usePublicAttendees = ({
  eventId,
  enabled,
  params,
}: {
  eventId: string;
  enabled: boolean;
  params?: string;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, eventId, params],
    queryFn: async () => {
      return await getPublicAttendees({
        baseUrl: ROOT_URL ?? '',
        eventId,
        params,
      });
    },
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicPortfolioByAccount = ({
  accountId,
  page = 1,
  slug,
}: {
  accountId: string;
  page?: number;
  slug?: string;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, accountId, slug, page],
    queryFn: async () =>
      await getPublicPortfoliosByAccount({
        baseUrl: ROOT_URL ?? '',
        accountId,
        page,
        slug,
      }),
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicEventDetails = ({ eventId }: { eventId: string }) => {
  return useQuery({
    queryKey: [ROOT_URL, eventId],
    queryFn: async () =>
      await getPublicEventDetailsById({
        baseUrl: ROOT_URL ?? '',
        eventId,
      }),
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicEventDetailsBySlug = ({
  eventSlug,
  enabled,
}: {
  eventSlug: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, eventSlug],
    queryFn: async () =>
      await getPublicEventDetailsBySlug({
        baseUrl: ROOT_URL ?? '',
        eventSlug,
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicPortfolioEvents = ({
  accountId,
  portfolioSlug,
  params,
  enabled,
}: {
  accountId: string;
  portfolioSlug: string;
  params: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, accountId, portfolioSlug, params],
    queryFn: async () =>
      await getPublicPortfolioEvents({
        baseUrl: ROOT_URL ?? '',
        accountId,
        portfolioSlug,
        params: params ?? '',
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicEventPageDetailsBySlug = ({
  eventId,
  pageSlug,
  enabled,
}: {
  eventId: string;
  pageSlug: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, pageSlug, eventId],
    queryFn: async () =>
      await getPublicEventPageDetailsBySlug({
        baseUrl: ROOT_URL ?? '',
        eventId,
        pageSlug,
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicGroupDetailsById = ({
  groupId,
  enabled,
}: {
  groupId: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, groupId],
    queryFn: async () =>
      await getPublicGroupDetailsById({
        baseUrl: ROOT_URL ?? '',
        groupId,
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicCoLocatedEventsByEventId = ({
  eventId,
  enabled,
}: {
  eventId: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, eventId],
    queryFn: async () =>
      await getPublicCoLocatedEventsByEventId({
        baseUrl: ROOT_URL ?? '',
        eventId,
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};

export const usePublicAgendas = ({
  page = 1,
  language = 'en',
  eventIds,
  enabled,
}: {
  page?: number;
  language?: string;
  eventIds: string[];
  enabled: boolean;
}) => {
  return useQueries({
    queries: eventIds?.map((event: string) => {
      return {
        queryKey: [ROOT_URL, page, language, event, 'agenda'],
        queryFn: async () => {
          return await getPublicAgenda({
            baseUrl: ROOT_URL ?? '',
            page,
            eventId: event,
            language,
          });
        },
        enabled,
        staleTime: 180000,
        retry: false,
      };
    }),
  });
};

export const usePublicExhibitions = ({
  page = 1,
  language = 'en',
  eventIds,
  enabled,
}: {
  page?: number;
  language?: string;
  eventIds: string[];
  enabled: boolean;
}) => {
  return useQueries({
    queries: eventIds?.map((eventId: string) => {
      return {
        queryKey: [ROOT_URL, page, language, eventId, 'exhibitions'],
        queryFn: async () => {
          return await getPublicEventExhibitions({
            baseUrl: ROOT_URL ?? '',
            page,
            eventId,
            language,
          });
        },
        enabled,
        staleTime: 180000,
        retry: false,
      };
    }),
  });
};

export const useCreateVisitor = ({
  updatedData,
  newData,
}: {
  updatedData?: VisitorDataInput;
  newData?: VisitorDataInput;
}) =>
  useMutation({
    mutationFn: async (action: 'create' | 'update') =>
      await postVisitor({
        visitorData: (action === 'create'
          ? newData
          : updatedData) as VisitorDataInput,
      }),
  });

export const useCreateInbound = (
  inboundData: InboundData,
  secret?: string,
  onError?: (error: Error) => void
) =>
  useMutation({
    mutationFn: async (responseDetails?: string) =>
      await postInbound({
        inboundData: {
          ...inboundData,
          visitor: safeJSONParse(responseDetails)?.visitor ?? '',
        },
        secret: safeJSONParse(responseDetails)?.secret ?? secret ?? '',
      }),
    onError,
  });

export const useGetVisitor = ({
  visitorId,
  secret,
  enabled,
}: {
  visitorId: string;
  secret: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [ROOT_URL, visitorId, secret],
    queryFn: async () =>
      await getVisitor({
        visitorId,
        secret,
      }),
    enabled,
    staleTime: 180000,
    retry: false,
    networkMode: 'offlineFirst',
  });
};
