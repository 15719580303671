import { LANGUAGE } from '@utils/constants';

export const mappingInbound: Record<string, string> = {
  mr: 'Mr.',
  mrs: 'Mrs.',
  influencer: 'Influencer',
  decision_maker: 'DecisionMaker',
  owner_boardmember_partner: 'Owner_Board__Member_Partner',
  ceo_cxo_md: 'CXO_Partner_Owner_MD',
  vp_svp_globalhead: 'VP_SVP',
  director_headof_leader: 'Director_Head_Leader',
  seniormanager_manager_projectmanager_teamleader:
    'SeniorManager_Manager_ProjectManager',
  employee: 'Employee:',
  assistant: 'Assistant',
  enduser: 'EndUser',
  vendor: 'Vendor',
  academia: 'Academia',
  aerospacedefense: 'AerospaceDefense',
  apparel: 'Apparel',
  associations: 'Associations',
  automotive: 'AutomotiveTier1',
  banking: 'Banking',
  biotechnology: 'Biotechnology',
  chemicals: 'Chemicals',
  communications: 'Communications',
  construction: 'Construction',
  consumergoods: 'ConsumerGoods',
  consulting: 'Consulting',
  ecommerce: 'eCommerce',
  education: 'Education',
  electronics: 'Electronics',
  energy: 'Energy',
  engineering: 'Engineering',
  entertainment: 'Entertainment',
  environmental: 'Environmental',
  finance: 'Finance',
  foodbeverages: 'FoodBeverage',
  government: 'Government',
  hardwareprovider: 'HardwareProvider',
  healthcare: 'Healthcare',
  hospitality: 'Hospitality',
  insurance: 'Insurance',
  investor: 'Investor',
  legal: 'Legal',
  logistics: 'Logistics',
  machinery: 'Machinery',
  manufacturing: 'Manufacturing',
  media: 'Media',
  ngo: 'NGO',
  oilgas: 'OilGas',
  pharmaceuticals: 'Pharmaceuticals',
  retail: 'Retail',
  shipping: 'Shipping',
  softwareprovider: 'SoftwareProvider',
  startup: 'Startup',
  technology: 'Technology',
  technologyprovider: 'TechnologyProvider',
  telecommunications: 'Telecommunications',
  tier: 'Tier1',
  tourism: 'Tier1',
  transportation: 'Transportation',
  utilities: 'Utilities',
  other: 'Other',
  afghanistan: 'AFGHANISTAN',
  albania: 'ALBANIA',
  algeria: 'ALGERIA',
  american_samoa: 'AMERICAN SAMOA',
  andorra: 'ANDORRA',
  angolla: 'ANGOLA',
  anguilla: 'ANGUILLA',
  antarctica: 'ANTARCTICA',
  antigua: 'ANTIGUA',
  argentina: 'ARGENTINA',
  armenia: 'ARMENIA',
  aruba: 'ARUBA',
  australia: 'AUSTRALIA',
  austria: 'AUSTRIA',
  azerbaijan: 'AZERBAIJAN',
  bahamas: 'BAHAMAS',
  bahrain: 'BAHRAIN',
  bangladesh: 'BANGLADESH',
  barbados: 'BARBADOS',
  belarus: 'BELARUS',
  belgium: 'BELGIUM',
  belize: 'BELIZE',
  benin: 'BENIN',
  bermuda: 'BERMUDA',
  bhutan: 'BHUTAN',
  bolivia: 'BOLIVIA',
  bosnia: 'BOSNIA',
  botswana: 'BOTSWANA',
  bouvet_island: 'BOUVET ISLAND',
  brazil: 'BRAZIL',
  british_virgin_islands: 'BRITISH VIRGIN ISLANDS',
  brunei: 'BRUNEI',
  bulgaria: 'BULGARIA',
  burkuina_faso: 'BURKINA FASO',
  burundi: 'BURUNDI',
  cambodia: 'CAMBODIA',
  cameroon: 'CAMEROON',
  canada: 'CANADA',
  cape_verde_islands: 'CAPE VERDE ISLANDS',
  cayman_islands: 'CAYMAN ISLANDS',
  chad: 'CHAD',
  chile: 'CHILE',
  china: 'CHINA',
  cocos_islands: 'COCOS (KEELING) ISLAND',
  colombia: 'COLOMBIA',
  comoros: 'COMOROS',
  cook_islands: 'COOK ISLANDS',
  costa_rica: 'COSTA RICA',
  cotedivoire: 'IVORY COAST',
  croatia: 'CROATIA',
  cuba: 'CUBA',
  cyprus: 'CYPRUS',
  czech_republic: 'CZECH REPUBLIC',
  denmark: 'DENMARK',
  democratic_republic_of_congo: 'CONGO KINSHASA',
  djibouti: 'DJIBOUTI',
  dominica: 'DOMINICA',
  dominican_republic: 'DOMINICAN REPUBLIC',
  ecuador: 'ECUADOR',
  egypt: 'EGYPT',
  el_salvador: 'EL SALVADOR',
  equatorial_guinea: 'EQUATORIAL GUINEA',
  estonia: 'ESTONIA',
  ethiopia: 'ETHIOPIA',
  falkland_islands: 'FALKLAND ISLANDS',
  faroe_islands: 'FAEROE ISLANDS',
  fiji: 'FIJI',
  finland: 'FINLAND',
  french_guiana: 'FRENCH GUIANA',
  french_polynesia: 'FRENCH POLYNESIA',
  french_southern_and_antarctic_lands: 'FRENCH SOUTHERN AND ANTARCTIC LANDS',
  gabon: 'GABON',
  gambia: 'GAMBIA',
  germany: 'GERMANY',
  georgia: 'GEORGIA',
  ghana: 'GHANA',
  gibraltar: 'GIBRALTAR',
  greece: 'GREECE',
  greenland: 'GREENLAND',
  grenada: 'GRENADA',
  guadeloupe: 'GUADELOUPE',
  guam: 'GUAM',
  guatemala: 'GUATEMALA',
  guinea: 'GUINEA',
  guinea_bissau: 'GUINEA-BISSAU',
  guyana: 'GUYANA',
  haiti: 'HAITI',
  heard_island_and_mcdonald_islands: 'HEARD ISLAND AND MCDONALD ISLANDS',
  honduras: 'HONDURAS',
  hong_kong: 'HONG KONG',
  hungary: 'HUNGARY',
  iceland: 'ICELAND',
  india: 'INDIA',
  indonesia: 'INDONESIA',
  iran: 'IRAN',
  iraq: 'IRAQ',
  ireland: 'IRELAND',
  israel: 'ISRAEL',
  italy: 'ITALY',
  jamaica: 'JAMAICA',
  jan_mayen: 'JAN MAYEN',
  japan: 'JAPAN',
  jersey: 'JERSEY',
  johnston_atoll: 'JOHNSTON ATOLL',
  jordan: 'JORDAN',
  kazakhstan: 'KAZAKHSTAN',
  kenya: 'KENYA',
  kiribati: 'KIRIBATI',
  kosovo: 'KOSOVO',
  kuwait: 'KUWAIT',
  kyrgyzstan: 'KYRGYZSTAN',
  laos: 'LAOS',
  latvia: 'LATVIA',
  lebanon: 'LEBANON',
  lesotho: 'LESOTHO',
  liberia: 'LIBERIA',
  libya: 'LIBYA',
  liechtenstein: 'LIECHTENSTEIN',
  lithuania: 'LITHUANIA',
  luxembourg: 'LUXEMBOURG',
  macau: 'MACAO',
  macedonia: 'MACEDONIA',
  madagascar: 'MADAGASCAR',
  malawi: 'MALAWI',
  malaysia: 'MALAYSIA',
  maldives: 'MALDIVES',
  mali: 'MALI',
  malta: 'MALTA',
  marshall_islands: 'MARSHALL ISLANDS',
  martinique: 'MARTINIQUE',
  mauritania: 'MAURITANIA',
  mauritius: 'MAURITIUS',
  mexico: 'MEXICO',
  micronesia: 'MICRONESIA',
  midway_islands: 'MIDWAY ISLANDS',
  moldova: 'MOLDOVA',
  monaco: 'MONACO',
  mongolia: 'MONGOLIA',
  montenegro: 'MONTENEGRO',
  montserrat: 'MONTSERRAT',
  morocco: 'MOROCCO',
  mozambique: 'MOZAMBIQUE',
  myanmar: 'MYANMAR',
  namibia: 'NAMIBIA',
  nepal: 'NEPAL',
  netherlands: 'NETHERLANDS',
  netherlands_antilles: 'NETHERLANDS ANTILLES',
  new_caledonia: 'NEW CALEDONIA',
  new_zealand: 'NEW ZEALAND',
  nicaragua: 'NICARAGUA',
  niger: 'NIGER',
  nigeria: 'NIGERIA',
  norfolk_island: 'NORFOLK ISLAND',
  north_korea: 'KOREA, NORTH',
  norway: 'NORWAY',
  oman: 'OMAN',
  pacific_ocean: 'PACIFIC ISLAND',
  pakistan: 'PAKISTAN',
  palau: 'PALAU',
  panama: 'PANAMA',
  papa_new_guinea: 'PAPUA NEW GUINEA',
  paraguay: 'PARAGUAY',
  peru: 'PERU',
  philippines: 'PHILIPPINES',
  pitcairn_islands: 'PITCAIRN ISLANDS',
  poland: 'POLAND',
  portugal: 'PORTUGAL',
  puerto_rico: 'PUERTO RICO',
  qatar: 'QATAR',
  reunion: 'REUNION',
  republic_of_the_congo: 'CONGO, REPUBLIC OF THE',
  romania: 'ROMANIA',
  russia: 'RUSSIA',
  rwanda: 'RWANDA',
  saint_helena: 'SAINT HELENA',
  saint_kitts_and_nevis: 'SAINT KITTS AND NEVIS',
  saint_lucia: 'SAINT LUCIA',
  saint_pierre_and_miquelon: 'SAINT PIERRE AND MIQUELON',
  saint_vincent_and_the_grenadines: 'SAINT VINCENT AND THE GRENADINES',
  samoa: 'SAMOA',
  san_marino: 'SAN MARINO',
  sao_tome_and_principe: 'SAO TOME AND PRINCIPE',
  saudi_arabia: 'SAUDI ARABIA',
  senegal: 'SENEGAL',
  serbia: 'SERBIA',
  seychelles: 'SEYCHELLES',
  sierra_leone: 'SIERRA LEONE',
  singapore: 'SINGAPORE',
  slovakia: 'SLOVAKIA',
  slovenia: 'SLOVENIA',
  solomon_islands: 'SOLOMON ISLANDS',
  somalia: 'SOMALIA',
  south_africa: 'SOUTH AFRICA',
  south_korea: 'KOREA, SOUTH',
  spain: 'SPAIN',
  sri_lanka: 'SRI LANKA',
  sudan: 'SUDAN',
  suriname: 'SURINAME',
  svalbard: 'SVALBARD',
  swaziland: 'SWAZILAND',
  sweden: 'SWEDEN',
  syria: 'SYRIA',
  taiwan: 'TAIWAN',
  tajikistan: 'TAJIKISTAN',
  tanzania: 'TANZANIA',
  thailand: 'THAILAND',
  togo: 'TOGO',
  tonga: 'TONGA',
  trinidad: 'TRINIDAD AND TOBAGO',
  tunisia: 'TUNISIA',
  turkey: 'TURKEY',
  turkmenistan: 'TURKMENISTAN',
  turks_and_caicos_islands: 'TURKS AND CAICOS ISLANDS',
  tuvalu: 'TUVALU',
  uganda: 'UGANDA',
  ukraine: 'UKRAINE',
  united_arab_emirates: 'UNITED ARAB EMIRATES',
  uruguay: 'URUGUAY',
  uzbekistan: 'UZBEKISTAN',
  vanuatu: 'VANUATU',
  venezuela: 'VENEZUELA',
  vietnam: 'VIETNAM',
  wake_island: 'WAKE ISLAND',
  wallis_and_futuna: 'WALLIS AND FUTUNA',
  west_bank: 'WEST BANK',
  western_sahara: 'WESTERN SAHARA',
  yemen: 'YEMEN',
  zambia: 'ZAMBIA',
  zimbabwe: 'ZIMBABWE',
  palmyra_atoll: 'palmyra_atoll',
  paracel_islands: 'paracel_islands',
  south_georgia_and_the_south_sandwich_islands:
    'south_georgia_and_the_south_sandwich_islands',
  spratly_islands: 'spratly_islands',
  tokelau: 'tokelau',
  trommelin_island: 'trommelin_island',
  nauru: 'nauru',
  navassa_island: 'navassa_island',
  mayotte: 'mayotte',
  ashmore_and_cartier_islands: 'ashmore_and_cartier_islands',
  atlantic_ocean: 'atlantic_ocean',
  baker_island: 'baker_island',
  bassas_da_india: 'bassas_da_india',
  central_african_republic: 'central_african_republic',
  christmas_island: 'christmas_island',
  clipperton_island: 'clipperton_island',
  coral_sea_islands: 'coral_sea_islands',
  east_timor: 'east_timor',
  eritrea: 'eritrea',
  europaisland: 'europaisland',
  gaza_strip: 'gaza_strip',
  glorioso_islands: 'glorioso_islands',
  guernsey: 'guernsey',
  howland_island: 'howland_island',
  indian_ocean: 'indian_ocean',
  isle_of_man: 'isle_of_man',
  jarvisiland: 'jarvisiland',
  juan_de_nova_island: 'juan_de_nova_island',
  kingman_reef: 'kingman_reef',
  kerguelen_archipelago: 'kerguelen_archipelago',
  niue: 'niue',
  north_sea: 'north_sea',
  northern_mariana_islands: 'northern_mariana_islands',
};

const generateMappings = <T extends string>(
  keysArray: T[],
  localizationPrefix?: string,
  predefinedMapping?: { [key in T]: string }
) => {
  const keysEnum = keysArray.reduce((a, c) => ({ ...a, [c]: c }), {}) as {
    [key in T]: key;
  };

  const mapping =
    predefinedMapping ??
    (keysArray.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: `${localizationPrefix ?? ''}${cur}`,
      }),
      {}
    ) as { [key in T]: string });

  const selectOptions = keysArray.map(
    key => ({ value: key, label: mapping[key] }) as const
  );

  const selectInboundOptions = keysArray.map(
    key =>
      ({ value: mappingInbound?.[key] ?? key, label: mapping[key] }) as const
  );
  const getLocalizedString = (key: string, fallback = ''): string => {
    if (!key?.length) return fallback;
    return mapping?.[key as keyof typeof mapping] || fallback;
  };

  return [
    keysEnum,
    keysArray,
    mapping,
    selectOptions,
    getLocalizedString,
    selectInboundOptions,
  ] as const;
};

export const [
  typeOfBusinessKeys,
  typeOfBusinessKeysArray,
  typesOfBusinessMapping,
  typesOfBusinessSelectOptions,
  getTypesOfBusinessSelectOptions,
  typesOfBusinessSelectInboundOptions,
] = generateMappings(['', 'enduser', 'vendor'], 'type-of-business.');

export const [
  jobLevelKeys,
  jobLevelKeysArray,
  jobLevelsMapping,
  jobLevelsSelectOptions,
  getJobLevelsLocalString,
  jobLevelsSelectInboundOptions,
] = generateMappings(
  [
    '',
    'owner_boardmember_partner',
    'ceo_cxo_md',
    'vp_svp_globalhead',
    'director_headof_leader',
    'seniormanager_manager_projectmanager_teamleader',
    'employee',
    'assistant',
  ],
  'job-levels.'
);

export const [
  companySizeKeys,
  companySizeKeysArray,
  companySizesMapping,
  companySizesSelectOptions,
  getCompanySizesLocalString,
] = generateMappings(
  [
    '',
    '50',
    '100',
    '500',
    '1000',
    '5000',
    '10000',
    '30000',
    '100000',
    '1000000',
  ],
  undefined,
  {
    '': '',
    '50': '< 50',
    '100': '50 - 100',
    '500': '100 - 500',
    '1000': '500 - 1.000',
    '5000': '1.000 - 5.000',
    '10000': '5.000 - 10.000',
    '30000': '10.000 - 30.000',
    '100000': '30.000 - 100.000',
    '1000000': '> 100.000',
  }
);

export const [
  countryKeys,
  countryKeysArray,
  countriesMapping,
  countriesSelectOptions,
  getCountriesLocalString,
  countriesSelectInboundOptions,
] = generateMappings(
  [
    '',
    'austria',
    'germany',
    'france',
    'switzerland',
    'united_kingdom',
    'usa',
    'afghanistan',
    'albania',
    'algeria',
    'american_samoa',
    'andorra',
    'angolla',
    'anguilla',
    'antarctica',
    'antigua',
    'argentina',
    'armenia',
    'arcticocean',
    'aruba',
    'ashmore_and_cartier_islands',
    'atlantic_ocean',
    'australia',
    'azerbaijan',
    'bahamas',
    'bahrain',
    'baker_island',
    'bangladesh',
    'barbados',
    'bassas_da_india',
    'belarus',
    'belgium',
    'belize',
    'benin',
    'bermuda',
    'bhutan',
    'bolivia',
    'bosnia',
    'botswana',
    'bouvet_island',
    'brazil',
    'british_virgin_islands',
    'brunei',
    'bulgaria',
    'burkuina_faso',
    'burundi',
    'cambodia',
    'cameroon',
    'canada',
    'cape_verde_islands',
    'cayman_islands',
    'central_african_republic',
    'chad',
    'chile',
    'china',
    'christmas_island',
    'clipperton_island',
    'cocos_islands',
    'colombia',
    'comoros',
    'cook_islands',
    'coral_sea_islands',
    'costa_rica',
    'cotedivoire',
    'croatia',
    'cuba',
    'cyprus',
    'czech_republic',
    'denmark',
    'democratic_republic_of_congo',
    'djibouti',
    'dominica',
    'dominican_republic',
    'east_timor',
    'ecuador',
    'egypt',
    'el_salvador',
    'equatorial_guinea',
    'eritrea',
    'estonia',
    'ethiopia',
    'europaisland',
    'falkland_islands',
    'faroe_islands',
    'fiji',
    'finland',
    'french_guiana',
    'french_polynesia',
    'french_southern_and_antarctic_lands',
    'gabon',
    'gambia',
    'gaza_strip',
    'georgia',
    'ghana',
    'gibraltar',
    'glorioso_islands',
    'greece',
    'greenland',
    'grenada',
    'guadeloupe',
    'guam',
    'guatemala',
    'guernsey',
    'guinea',
    'guinea_bissau',
    'guyana',
    'haiti',
    'heard_island_and_mcdonald_islands',
    'honduras',
    'hong_kong',
    'howland_island',
    'hungary',
    'iceland',
    'india',
    'indian_ocean',
    'indonesia',
    'iran',
    'iraq',
    'ireland',
    'isle_of_man',
    'israel',
    'italy',
    'jamaica',
    'jan_mayen',
    'japan',
    'jarvisiland',
    'jersey',
    'johnston_atoll',
    'jordan',
    'juan_de_nova_island',
    'kazakhstan',
    'kenya',
    'kingman_reef',
    'kiribati',
    'kerguelen_archipelago',
    'kosovo',
    'kuwait',
    'kyrgyzstan',
    'laos',
    'latvia',
    'lebanon',
    'lesotho',
    'liberia',
    'libya',
    'liechtenstein',
    'lithuania',
    'luxembourg',
    'macau',
    'macedonia',
    'madagascar',
    'malawi',
    'malaysia',
    'maldives',
    'mali',
    'malta',
    'marshall_islands',
    'martinique',
    'mauritania',
    'mauritius',
    'mayotte',
    'mexico',
    'micronesia',
    'midway_islands',
    'moldova',
    'monaco',
    'mongolia',
    'montenegro',
    'montserrat',
    'morocco',
    'mozambique',
    'myanmar',
    'namibia',
    'nauru',
    'navassa_island',
    'nepal',
    'netherlands',
    'netherlands_antilles',
    'new_caledonia',
    'new_zealand',
    'nicaragua',
    'niger',
    'nigeria',
    'niue',
    'norfolk_island',
    'north_korea',
    'north_sea',
    'northern_mariana_islands',
    'norway',
    'oman',
    'pacific_ocean',
    'pakistan',
    'palau',
    'palmyra_atoll',
    'panama',
    'papa_new_guinea',
    'paracel_islands',
    'paraguay',
    'peru',
    'philippines',
    'pitcairn_islands',
    'poland',
    'portugal',
    'puerto_rico',
    'qatar',
    'reunion',
    'republic_of_the_congo',
    'romania',
    'russia',
    'rwanda',
    'saint_helena',
    'saint_kitts_and_nevis',
    'saint_lucia',
    'saint_pierre_and_miquelon',
    'saint_vincent_and_the_grenadines',
    'samoa',
    'san_marino',
    'sao_tome_and_principe',
    'saudi_arabia',
    'senegal',
    'serbia',
    'seychelles',
    'sierra_leone',
    'singapore',
    'slovakia',
    'slovenia',
    'solomon_islands',
    'somalia',
    'south_africa',
    'south_georgia_and_the_south_sandwich_islands',
    'south_korea',
    'spain',
    'spratly_islands',
    'sri_lanka',
    'sudan',
    'suriname',
    'svalbard',
    'swaziland',
    'sweden',
    'syria',
    'taiwan',
    'tajikistan',
    'tanzania',
    'thailand',
    'togo',
    'tokelau',
    'tonga',
    'trinidad',
    'trommelin_island',
    'tunisia',
    'turkey',
    'turkmenistan',
    'turks_and_caicos_islands',
    'tuvalu',
    'uganda',
    'ukraine',
    'united_arab_emirates',
    'uruguay',
    'uzbekistan',
    'vanuatu',
    'venezuela',
    'vietnam',
    'wake_island',
    'wallis_and_futuna',
    'west_bank',
    'western_sahara',
    'yemen',
    'zambia',
    'zimbabwe',
  ],
  'countries.'
);

export const [
  jobResponsibilityKeys,
  jobResponsibilityKeysArray,
  jobResponsibilitiesMapping,
  jobResponsibilitiesSelectOptions,
  getJobResponsibilitiesLocalString,
  jobResponsibilitiesSelectInboundOptions,
] = generateMappings(
  ['', 'influencer', 'decision_maker'],
  'job-responsibility.'
);

export const [
  industryKeys,
  industryKeysArray,
  industriesMapping,
  industriesSelectOptions,
  getIndustriesLocalString,
  industriesSelectInboundOptions,
] = generateMappings(
  [
    '',
    'academia',
    'aerospacedefense',
    'apparel',
    'associations',
    'automotive',
    'banking',
    'biotechnology',
    'chemicals',
    'communications',
    'construction',
    'consumergoods',
    'consulting',
    'ecommerce',
    'education',
    'electronics',
    'energy',
    'engineering',
    'entertainment',
    'environmental',
    'finance',
    'foodbeverages',
    'government',
    'hardwareprovider',
    'healthcare',
    'hospitality',
    'insurance',
    'investor',
    'legal',
    'logistics',
    'machinery',
    'manufacturing',
    'media',
    'ngo',
    'oilgas',
    'pharmaceuticals',
    'retail',
    'shipping',
    'softwareprovider',
    'startup',
    'technology',
    'technologyprovider',
    'telecommunications',
    'tier',
    'tourism',
    'transportation',
    'utilities',
    'other',
  ],
  'industries.'
);

export const [
  regionKeys,
  regionKeysArray,
  regionMapping,
  regionSelectOptions,
  getLocalizedRegion,
] = generateMappings(['asia', 'europe', 'usa'], 'region-mapping.');

export const [
  languageKeys,
  languageKeysArray,
  languageMapping,
  languageSelectOptions,
  getLocalizedLanguage,
] = generateMappings([LANGUAGE.GERMAN, LANGUAGE.ENGLISH], 'language-mapping.');

export const [
  salutationsKeys,
  salutationsKeysArray,
  salutationsMapping,
  salutationsSelectOptions,
  getLocalizedSalutations,
  salutationsSelectInboundOptions,
] = generateMappings(['mr', 'mrs', 'divers'], 'salutation-mapping.');

export const [
  periodKeys,
  periodKeysArray,
  periodMapping,
  periodSelectOptions,
  getLocalizedPeriod,
] = generateMappings(
  ['jan-mar', 'apr-jun', 'jul-sep', 'oct-dec'],
  'period-mapping.'
);

export const quarterMapping = [
  { key: 'jan-mar', values: [1, 2, 3] },
  { key: 'apr-jun', values: [4, 5, 6] },
  { key: 'jul-sep', values: [7, 8, 9] },
  { key: 'oct-dec', values: [10, 11, 12] },
];

export const bookingLink: Record<string, string> = {
  'https://test-api.hubs101.com': 'https://staging-booking.hubs101.com/',
  'https://staging-api.we-conect.com': 'https://staging-booking.hubs101.com/',
  'https://api.hubs101.com': 'https://booking.hubs101.com/',
};
