import { CenterItem } from '@components/LinearLayout';
import { PBold } from '../Typography';
import { styled } from 'styled-components';

export const ButtonRoot = styled(CenterItem)<{
  $backgroundColor?: string;
  $padding?: string;
  $border?: string;
  $borderRadius?: string;
  $width?: string;
  $hoverBackgroundColor?: string;
  $disabled: boolean;
}>`
  padding: ${({ $padding }) => $padding ?? ' 0.3rem 1rem'};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  border: ${({ $border }) => $border};
  background-color: ${props =>
    props?.$backgroundColor ? props.$backgroundColor : props.theme.color.white};
  width: ${({ $width }) => $width};
  &:hover {
    cursor: pointer;
    background-color: ${({ $hoverBackgroundColor }) => $hoverBackgroundColor};
    ${({ $disabled, theme }) =>
      $disabled &&
      `background-color:${theme.color.shadeGrey1}; cursor: not-allowed;`}
  }
  transition: background-color 600ms cubic-bezier(0.4, 0.47, 0.13, 0.88);
  ${({ $disabled, theme }) =>
    $disabled &&
    `background-color:${theme.color.shadeGrey1}; cursor: not-allowed;`}
`;

export const ButtonTitle = styled(PBold)<{
  $textColor?: string;
  $fontSize?: string;
  $hoverTextColor?: string;
  $isHovered?: boolean;
  $lineHeight?: string;
}>`
  text-align: center;
  font-size: ${({ $fontSize }) => $fontSize};
  line-height: ${({ $lineHeight }) => $lineHeight};
  white-space: nowrap;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoint.width480}) {
    font-size: ${({ theme }) => theme.fontSize.size18};
    line-height: ${({ theme }) => theme.lineHeight.height115};
  }
  color: ${({ $hoverTextColor, $isHovered, $textColor, theme }) => {
    if ($isHovered) return $hoverTextColor;
    else if ($textColor) return $textColor;
    return theme.color.primaryText;
  }};
`;
